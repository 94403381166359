import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/components/Hero/Hero.css';

import ThemeContext from "../../contexts/ThemeContext";

const Hero = ({ content }) => {
    const { currentTheme, setTheme } = useContext(ThemeContext);

    if (!content) return null;

    const { isImageAnimated, isImageHidden, isImageRound, direction, headerColor, isHeaderHidden, topHeader, isLanding, imgUrl, linkUrl, projectId, bottomHeader, tickerText } = content;

    const getAnimationClass = isAnimated => isAnimated === 'true' ? 'planetarySpin' : '';
    const getVisibilityClass = isHidden => isHidden === 'true' ? 'hide' : '';
    const getBorderStyleClass = isRound => isRound === 'true' ? 'border-circle' : 'border-rounded';
    const getContentDirection = dir => dir || '';
    const getHeaderColor = color => color || '';
    const switchTheme = () => {
        const newTheme = currentTheme === "woodland-hues" ? "midnight-velvet" : "woodland-hues";
        setTheme(newTheme);
    };


    const getImgElement = () => (
        <img
            className={`logo ${getAnimationClass(isImageAnimated)} ${getVisibilityClass(isImageHidden)} ${getBorderStyleClass(isImageRound)}`}
            src={imgUrl}
            alt="logo"
            loading="eager"
            onClick={isLanding ? switchTheme : undefined}
            style={{ cursor: isLanding ? "pointer" : "default" }} // Optional: show pointer cursor when clickable
        />
    );

    return (
        <div className={`brandWrapper ${getContentDirection(direction)}`}>
            <h1 className={`topHeader ${getHeaderColor(headerColor)} ${getVisibilityClass(isHeaderHidden)}`}>{topHeader || ''}</h1>
            {imgUrl &&
                <div className={`logoBox ${getBorderStyleClass(isImageRound)}`}>
                    {linkUrl
                        ? <Link to={`${linkUrl}?modal=${projectId}`}>{getImgElement()}</Link>
                        : getImgElement()
                    }
                </div>
            }
            <h1 className={`bottomHeader ${getHeaderColor(headerColor)} ${getVisibilityClass(isHeaderHidden)}`}>{bottomHeader || ''}</h1>
            {tickerText && (
                <div className="ticker">
                    <span>{tickerText}</span>
                </div>
            )}
        </div>
    );
};

export default Hero;